<template>
    <div id="page-lap-pelayanan-harian">
      <b-container fluid>
        <b-row>
          <b-col cols="12" sm="12" md="12" lg="12" xl="12">
            <b-card>
              <b-row>
                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Dari
                    </template>
                    <date-picker
                      style="width: 100%"
                      id="tanggal_awal"
                      format="DD-MM-YYYY"
                      v-model="data_search.tanggal_awal"
                    ></date-picker>
                  </b-form-group>
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Sampai
                    </template>
                    <date-picker
                      style="width: 100%"
                      id="tanggal_akhir"
                      format="DD-MM-YYYY"
                      v-model="data_search.tanggal_akhir"
                    ></date-picker>
                  </b-form-group>
                </b-col>
                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                  <!-- <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Petugas Kasir
                    </template>
                    <multiselect
                      :options="listPetugasKasir"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="nama_tenaga_medis"
                      size="sm"
                      v-model="data_search.petugas_kasir"
                      :disabled="disabled_field"
                    ></multiselect>
                  </b-form-group>
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Tenaga Medis
                    </template>
                    <multiselect
                      :options="listTenagaMedis"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="nama_tenaga_medis"
                      size="sm"
                      v-model="data_search.tenaga_medis"
                      :disabled="disabled_field"
                    ></multiselect>
                  </b-form-group> -->
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Asuransi
                    </template>
                    <multiselect
                      :options="listAsuransi"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="text"
                      size="sm"
                      v-model="data_search.asuransi"
                      :disabled="disabled_field"
                    ></multiselect>
                  </b-form-group>
                </b-col>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <hr>
                  <div style="width:100%;display:flex">
                    <div style="width:50%;height:30px;display:flex;justify-content:flex-start;align-items:center">
                      <h6 style="margin:0;font-weight:bold">Puskesmas</h6>
                    </div>
                    <div style="width:50%;height:30px;display:flex;justify-content:flex-end;align-items:center">
                      <b-button variant="outline-primary" size="sm">
                        <b-form-checkbox
                          id="check_puskesmas"
                          name="check_puskesmas"
                          v-model="checkedPuskesmas"
                          @change="toggleSelectPuskesmas()"
                        >
                          <span class="pt-1">Pilih Semua</span>
                        </b-form-checkbox>
                      </b-button>
                    </div>
                  </div>
                  <hr>
                  <b-row style="padding-left:7.5px;margin-top:15px;">
                    <b-col v-for="(item, idx) in listPuskesmas" :key="idx" cols="12" sm="12" md="6" lg="4" xl="4">
                        <b-form-checkbox
                          v-model="item.checked"
                        >
                          {{ item.nama_puskesmas }}
                        </b-form-checkbox>
                    </b-col>
                  </b-row>
                </b-col>
                <!-- <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                  <div style="width:100%;display:flex">
                    <div style="width:50%;height:30px;display:flex;justify-content:flex-start;align-items:center">
                      <h6 style="margin:0;font-weight:bold">Poli</h6>
                    </div>
                    <div style="width:50%;height:30px;display:flex;justify-content:flex-end;align-items:center">
                      <b-button variant="outline-primary" size="sm">
                        <b-form-checkbox
                          id="check_poli"
                          name="check_poli"
                          v-model="checkedPoli"
                          @change="toggleSelectPoli()"
                        >
                          <span class="pt-1">Pilih Semua</span>
                        </b-form-checkbox>
                      </b-button>
                    </div>
                  </div>
                  <hr>
                  <b-row style="padding-left:7.5px;margin-top:15px;">
                    <b-col v-for="(item, idx) in listPoli" :key="idx" cols="12" sm="12" md="12" lg="6" xl="6">
                        <b-form-checkbox
                          v-model="item.checked"
                        >
                          {{ item.nama_poli }}
                        </b-form-checkbox>
                    </b-col>
                  </b-row>
                </b-col> -->
              </b-row>
              <b-row class="mt-3">
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <hr>
                  <div style="display:flex;justify-content:flex-start">
                    <b-button @click="getSearch()" variant="primary" class="mr-2">Tampilkan</b-button>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <hr>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <h4 style="text-align:center"><strong>LAPORAN HARIAN - PENDAPATAN TINDAKAN</strong></h4>
                  <h5 style="text-align:center; margin-top:5px"><strong>Total Kunjungan : {{ totalRows }}</strong></h5>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="12" lg="12">
                  <b-table-simple responsive class="table-lap-pelayanan-harian">
                    <b-thead>
                      <b-tr class="text-center">
                        <b-th rowspan="2" style="width: 10px; position:sticky; left:0; min-width:50px; background-color: #fff;">No</b-th>
                        <b-th rowspan="2" style="position:sticky; left:50px; min-width:150px; background-color: #fff;">Tanggal</b-th>
                        <b-th rowspan="2" style="position:sticky; left:200px; min-width:160px; background-color:#fff">Nama Pasien</b-th>
                        <b-th rowspan="2" style="min-width: 130px;">Jenis Kelamin</b-th>
                        <b-th rowspan="2" style="min-width: 200px;">Alamat</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">Poli/Ruangan	</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">Umur</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">Asuransi</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">No Asuransi	</b-th>
                        <b-th colspan="6">Jumlah Biaya</b-th>
                      </b-tr>
                      <b-tr class="text-center">
                        <b-th rowspan="2" style="min-width: 150px;">Tenaga Medis</b-th>
                        <b-th rowspan="2" style="min-width: 150px;">Asisten</b-th>
                        <b-th rowspan="2" style="min-width: 250px;">Tindakan</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">Tarif</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">Jumlah</b-th>
                        <b-th rowspan="2" style="min-width: 100px;">Total</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr v-for="(item, idx) in listPendapatanTindakan" :key="idx">
                        <b-td v-if="item.type == 'head'" :rowspan="item.data_tindakan.length > 1 ? item.data_tindakan.length : 1" style="position:sticky;left:0;min-width:50px;background-color: #fff;">{{ item.no }}</b-td>
                        <b-td v-if="item.type == 'head'" :rowspan="item.data_tindakan.length > 1 ? item.data_tindakan.length : 1" style="position:sticky;left:50px;min-width:150px;background-color: #fff;">{{ $moment(item.waktu_mulai).format('LLLL') }}</b-td>
                        <b-td v-if="item.type == 'head'" :rowspan="item.data_tindakan.length > 1 ? item.data_tindakan.length : 1" style="position:sticky;left:200px;min-width:160px;background-color: #fff;">{{ item.nama_lengkap }}</b-td>
                        <b-td v-if="item.type == 'head'" :rowspan="item.data_tindakan.length > 1 ? item.data_tindakan.length : 1">{{ item.jenis_kelamin == 'P' ? 'Perempuan' : 'Laki - Laki' }}</b-td>
                        <b-td v-if="item.type == 'head'" :rowspan="item.data_tindakan.length > 1 ? item.data_tindakan.length : 1">{{ item.alamat_sekarang }}</b-td>
                        <b-td v-if="item.type == 'head'" :rowspan="item.data_tindakan.length > 1 ? item.data_tindakan.length : 1">{{ item.nama_poli }}</b-td>
                        <b-td v-if="item.type == 'head'" :rowspan="item.data_tindakan.length > 1 ? item.data_tindakan.length : 1">{{ item.usia_tahun }} tahun {{ item.usia_bulan }} bulan {{ item.usia_hari }} hari</b-td>
                        <b-td v-if="item.type == 'head'" :rowspan="item.data_tindakan.length > 1 ? item.data_tindakan.length : 1">{{ item.asuransi }}</b-td>
                        <b-td v-if="item.type == 'head'" :rowspan="item.data_tindakan.length > 1 ? item.data_tindakan.length : 1">{{ item.no_asuransi }}</b-td>
                        <b-td v-if="item.type == 'head'">{{ item.data_tindakan[0].nama_tenaga_medis }}</b-td>
                        <b-td v-if="item.type == 'head'">{{ item.data_tindakan[0].asisten }}</b-td>
                        <b-td v-if="item.type == 'head'">{{ item.data_tindakan[0].nama_tindakan }}</b-td>
                        <b-td v-if="item.type == 'head'">{{ $numberFormat(item.data_tindakan[0].jumlah_tarif_tindakan, 'idr') }}</b-td>
                        <b-td v-if="item.type == 'head'">{{ item.data_tindakan[0].jumlah_tindakan }}</b-td>
                        <b-td v-if="item.type == 'head'">{{ $numberFormat(item.data_tindakan[0].total_tarif, 'idr') }}</b-td>

                        <b-td v-if="item.type == 'sub'">{{ item.nama_tenaga_medis }}</b-td>
                        <b-td v-if="item.type == 'sub'">{{ item.asisten }}</b-td>
                        <b-td v-if="item.type == 'sub'">{{ item.nama_tindakan }}</b-td>
                        <b-td v-if="item.type == 'sub'">{{ $numberFormat(item.total_tarif, 'idr') }}</b-td>
                        <b-td v-if="item.type == 'sub'">{{ item.jumlah_tindakan }}</b-td>
                        <b-td v-if="item.type == 'sub'">{{ $numberFormat(item.jumlah_tarif_tindakan, 'idr') }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <!-- {{listPendapatanTindakan}} -->
      </b-container>
    </div>
</template>
  
<script>
import Multiselect from "vue-multiselect";
export default {
  name: "kunjungan_pasien",
  components: {
    Multiselect
  },
  data() {
    return {
      showing: false,
      variant: "success",
      msg: "",
      value: null,
      kunjungan_5: false,
      total: null,
      data_search: {
        tanggal_awal: null,
        tanggal_akhir: null,
        petugas_kasir: null,
        tenaga_medis: null,
        asuransi: null,
        puskesmas: [],
        poli: [],
      },
      totalRows: 0,
      listPendapatanTindakan: [],
      listAsuransi: [
        {value: 'bpjs', text: 'BPJS'},
        {value: 'umum', text: 'Umum'},
      ],
      listPetugasKasir: [],
      listTenagaMedis: [],
      checkedPuskesmas: false,
      listPuskesmas: [],
      checkedPoli: false,
      listPoli: [],
      fields: [
      {
        key: "no",
        label: "No",
        sortDirection: "desc",
        sortable: true,
        class: "table-number text-center",
      },
      {
        key: "tgl",
        label: "Tanggal",
        sortable: true,
        sortDirection: "desc",
        class: "text-left",
        // stickyColumn: true,
      },
      {
        key: "nama_lengkap",
        label: "Nama Pasien",
        sortable: true,
        sortDirection: "desc",
        class: "custom-w-nama text-left",
        // stickyColumn: true,
      },
      {
        key: "no_rm",
        label: "No. eRM",
        sortable: true,
        sortDirection: "desc",
        class: "text-left",
      },
      {
        key: "nik",
        label: "NIK",
        sortable: true,
        sortDirection: "desc",
        class: "text-left",
      },
      {
        key: "jenis_kelamin",
        label: "Jenis Kelamin",
        sortable: true,
        sortDirection: "desc",
        class: "text-center",
      },
      {
        key: "ttl",
        label: "Tempat & Tanggal Lahir",
        sortable: true,
        sortDirection: "desc",
        class: "custom-w-ttl text-left",
      },
      {
        key: "umur",
        label: "Umur",
        sortable: true,
        sortDirection: "desc",
        class: "custom-w-umur text-left",
      },
      {
        key: "pekerjaan",
        label: "Pekerjaan",
        sortable: true,
        sortDirection: "desc",
        class: "text-left",
      },
      {
        key: "alamat_sekarang",
        label: "Alamat",
        sortable: true,
        sortDirection: "desc",
        class: "custom-w-alamat text-left",
      },
      {
        key: "nama_kelurahan",
        label: "Kelurahan",
        sortable: true,
        sortDirection: "desc",
        class: "custom-w-umur text-left",
      },
      {
        key: "nama_ayah",
        label: "Nama Ayah",
        sortable: true,
        sortDirection: "desc",
        class: "text-left",
      },
      {
        key: "kunjungan",
        label: "Kunjungan",
        sortable: true,
        sortDirection: "desc",
        class: "text-left",
      },
      {
        key: "nama_poli",
        label: "Poli / Ruangan",
        sortable: true,
        sortDirection: "desc",
        class: "text-left",
      },
      {
        key: "asuransi",
        label: "Asuransi",
        sortable: true,
        sortDirection: "desc",
        class: "text-left",
      },
      {
        key: "no_kartu",
        label: "No. Asuransi",
        sortable: true,
        sortDirection: "desc",
        class: "text-left",
      },
      {
        key: "diagnosa",
        label: "Diagnosa",
        sortable: true,
        sortDirection: "desc",
        class: "text-left",
      },
      {
        key: "tindakan",
        label: "Tindakan",
        sortable: true,
        sortDirection: "desc",
        class: "text-left",
      },
      {
        key: "resep",
        label: "Resep",
        sortable: true,
        sortDirection: "desc",
        class: "text-left",
      },
      
    ],
    items: [],
    filter: null,
    tableBusy: false,
    disabled_field: false,
    };
  },
  computed: {
  },
  activated(){
    this.getData()
    this.reset()
  },
  methods: {
    async getData(){
      const vm = this
      vm.tableBusy = false
      try {
        const listPoli = await vm.$axios.post('/ms_poli/list')
        vm.listPoli = listPoli.data.status == 200 ? listPoli.data.data : []
        const listPuskesmas = await vm.$axios.post('/puskesmas/list')
        vm.listPuskesmas = listPuskesmas.data.status == 200 ? listPuskesmas.data.data : []
        // let listTenagaMedis = await vm.$axios.post("/tenaga_medis/list")
        // vm.listTenagaMedis = listTenagaMedis.data.status == 200 ? listTenagaMedis.data.data : []
        // let listPetugasKasir = await vm.$axios.post("/tenaga_medis/list", {nama_jenis_tenaga_medis: 'petugas kasir'})
        // vm.listPetugasKasir = listPetugasKasir.data.status == 200 ? listPetugasKasir.data.data : []
      } catch (error) {
        console.log(error)
      } finally {
        vm.tableBusy = false
      }
    },
    async getSearch(){
      const vm = this
      vm.tableBusy = false
      try {
        //puskesmas
        const bulk_puskesmas_id = []
        for (let i = 0; i < vm.listPuskesmas.length; i++) {
          const puskesmas = vm.listPuskesmas[i];
          if(puskesmas.checked){
            bulk_puskesmas_id.push(puskesmas.id)
          }
        }
        //poli
        const bulk_ms_poli_id = []
        for (let i = 0; i < vm.listPoli.length; i++) {
          const poli = vm.listPoli[i];
          if(poli.checked){
            bulk_ms_poli_id.push(poli.ms_poli_id)
          }
        }
        const res = await vm.$axios.post('/tindakan/laporan_pendapatan_tindakan', {
          tanggal_awal: vm.$moment(vm.data_search.tanggal_awal).format('YYYY-MM-DD'),
          tanggal_akhir: vm.$moment(vm.data_search.tanggal_akhir).format('YYYY-MM-DD'),
          jenis_kelamin: vm.data_search.jenis_kelamin ? vm.data_search.jenis_kelamin.value : null,
          ms_petugas_kasir_id: vm.data_search.petugas_kasir ? vm.data_search.petugas_kasir.tenaga_medis_id : null,
          ms_dokter_id: vm.data_search.tenaga_medis ? vm.data_search.tenaga_medis.tenaga_medis_id : null,
          asuransi: vm.data_search.asuransi ? vm.data_search.asuransi.value : null,
          bulk_puskesmas_id,
          bulk_ms_poli_id,
        })
        this.listPendapatanTindakan = []
        const listPendapatanTindakan = []
        if(res.data.status == 200){
          vm.totalRows = res.data.data.length
          for (let i = 0; i < res.data.data.length; i++) {
            const x = res.data.data[i];
            listPendapatanTindakan.push({
              ...x,
              no: i+1,
              type: 'head',
              asuransi: x.no_kartu ? 'BPJS' : 'UMUM',
              no_asuransi: x.no_kartu || '-',
            })
            for (let o = 0; o < x.data_tindakan.length; o++) {
              if(o == 0) continue
              const y = x.data_tindakan[o];
              listPendapatanTindakan.push({
                ...y,
                type: 'sub',
              })
            }
          }
          console.log('laporan_pendapatan_tindakan', res)
          this.listPendapatanTindakan = listPendapatanTindakan
        }
      } catch (error) {
        console.log(error)
      } finally {
        vm.tableBusy = false
      }
    },
    async toggleSelectPuskesmas () {
      const vm = this
      vm.listPuskesmas.forEach(function (data) {
        data.checked = vm.checkedPuskesmas;
      });
      vm.$forceUpdate()
    },
    async toggleSelectPoli () {
      const vm = this
      vm.listPoli.forEach(function (data) {
        data.checked = vm.checkedPoli;
      });
      vm.$forceUpdate()
    },
    async reset(){
      const vm = this
      vm.tableBusy = false
      vm.data_search = {
        tanggal_awal:  new Date(vm.$moment(vm.$store.state.tanggal_sesi).subtract(1, 'months')),
        tanggal_akhir: new Date(vm.$store.state.tanggal_sesi),
        petugas_kasir: null,
        tenaga_medis: null,
        asuransi: null,
        puskesmas: [],
        poli: [],
      }
      vm.getSearch()
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
};
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
#page-lap-pelayanan-harian .table-lap-pelayanan-harian th{
  background-color: #D52F65 !important ;
  color: #fff;
  font-weight: 600;
}

#page-lap-pelayanan-harian .table-lap-pelayanan-harian th, td{
  border: 1px solid #F1F1F1 !important;
}

#page-lap-pelayanan-harian .table-lap-pelayanan-harian th:first-child {
  border-top-left-radius: 8px;
}

#page-lap-pelayanan-harian .table-lap-pelayanan-harian th:last-child {
  border-top-right-radius: 8px;
}
</style>
